.newproject {
    border-radius: 5px;
    background: #F5F5F5;
    border: 2px solid #898989;
    border-style: dashed;
    width: 100%;
    height: 162px; /* Set the same height as other cards */
    padding: 0px 0px 20px;
    display: flex;
    flex-direction: column;
  }
  
  /* Additional styles for the PlusOutlined icon and heading */
  .newproject .anticon {
    font-size: 40px;
    color: #898989;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .newproject h3 {
    color: #9A9A9A;
    text-align: center;
    margin-top: 10px;
  }
  